import React from "react";
import { BackgroundBeams } from "~/components/marketing/BackgroundBeams";
import { LinkPreview } from "~/components/marketing/LinkPreview";
import AISuggestions from "./static/AISuggestions2.png";
import Graphs1 from "./static/Graphs1.png";
import ProductImage from "./static/Product2.png";
import Collab from "./static/Collab.png";
import Logo from "~/components/datapaper/Logo";

export const App = () => {
  return <main className="dark" data-mode="dark">
    <div style={{
      position: "fixed",
      top: 40,
      left: 40,
    }}>
      <Logo height={null} width={80} />
    </div>
    <h1>Data <span className="text-gray-400">meets</span> Documents</h1>
    <div className="text-neutral-500 dark:text-neutral-400 text-xl">
      with a touch of{" "}
      <LinkPreview
        src={AISuggestions}
        className="font-bold transition-all text-blue-500 hover:text-blue-600"
      >
        Multi-modal AI insights
      </LinkPreview>{", "}
      choices of {" "}
      <LinkPreview
        src={Graphs1}
        className="font-bold transition-all text-green-400 hover:text-green-500"
      >
        beautiful visualizations
      </LinkPreview>{" "}
      <br />
      and {" "}
      <LinkPreview
        src={Collab}
        className="font-bold transition-all text-yellow-400 hover:text-yellow-500"
      >
        real-time collaboration!
      </LinkPreview>{" "}

      <img className="shadow-sm rounded-lg border my-11 mb-8 hero-image" src={ProductImage} />

    </div>
    <div data-tf-live="01J5CMKRVXVGVJWV9MA4VXC0EG"></div><script src="//embed.typeform.com/next/embed.js"></script>

    <div style={{
      pointerEvents: "none",
      filter: "invert(1) hue-rotate(15deg) saturate(0)",
      opacity: 0.7,
      width: "100vw",
      height: "100vh",
      position: "fixed",
      zIndex: "-1",
      top: 0,
      left: 0
    }}>
      <BackgroundBeams />
    </div>

    <button onClick={() => {
      const { open, close, toggle, refresh } = window.tf.createPopup('Bu1VUzRa');
      open();
    }} className="bg-slate-800 no-underline group cursor-pointer relative shadow-2xl shadow-zinc-900 rounded-full p-px text-md leading-6  text-white-200 inline-block">
      <span className="absolute inset-0 overflow-hidden rounded-full">
        <span className="absolute inset-0 rounded-full bg-[image:radial-gradient(75%_100%_at_50%_0%,rgba(56,189,248,0.6)_0%,rgba(56,189,248,0)_75%)] opacity-0 transition-opacity duration-500 group-hover:opacity-100" />
      </span>
      <div className="relative flex items-center z-10 rounded-full bg-zinc-950 py-2 px-6 pr-3 hover:px-8 hover:pr-5 transition-all ring-1 ring-white/10 ">
        <span>
          Join our Waitlist
        </span>
        <svg
          fill="none"
          height="32"
          viewBox="0 0 24 24"
          width="32"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.75 8.75L14.25 12L10.75 15.25"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
        </svg>
      </div>
      <span className="absolute -bottom-0 left-[1.125rem] h-px w-[calc(100%-2.25rem)] bg-gradient-to-r from-emerald-400/0 via-emerald-400/90 to-emerald-400/0 transition-opacity duration-500 group-hover:opacity-40" />
    </button>
  </main>;
};
